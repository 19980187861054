import executeRequest from "../utils/apiService";

export const lookupService = {
    fetchServerIps: async (onSuccess, onFailure) => {
        return executeRequest({
            method: "GET",
            url: '/api/Lookup'
        })
            .then(onSuccess)
            .catch(onFailure);
    },
};